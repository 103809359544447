import { successColor } from 'assets/jss/material-kit-pro-react.js';

const styles = theme => ({
  container: {
    display: 'inline-flex',
    borderRadius: '35px',
    backgroundColor: '#ffffff40',
    border: '1px solid #fff',
    '&:hover': {
      backgroundColor: '#ffffff40',
      cursor: 'pointer',
    },
    padding: '4px 20px 4px 4px',
    alignItems: 'center',
  },
  logo: {
    height: '30px',
  },
  connected: {
    width: '10px',
    height: '10px',
    backgroundColor: '#00FF22',
    borderRadius: '50%',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  networkName: {
    margin: '0 0 0 8px',

    color: theme.palette.primary.main,
  },
});

export default styles;
