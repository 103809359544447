const styles = theme => ({
  container: {
    padding: '15px',
    margin: '40px 0 25px',
    backgroundColor: '#8B2F41',
    justifyContent: 'space-between',
    position: 'relative',
    borderRadius: '30px',
    border: '1px solid #F20B0B',
  },

  selectorContainer: {
    width: '70%',
    marginLeft: '63px',
  },

  selectorLabel: {
    color: '#fff',
    marginTop: '15px',
    marginLeft: '-60px',
    fontSize: '16px',
  },

  selector: {
    marginTop: '0px !important',
    border: '1px solid #fff',
    margin: '0 !important',
    padding: '5px 10px',
    borderRadius: '30px',
    color: '#fff',
  },

  boost: {
    color: '#5a8f69',
    fontWeight: 'bold',

    '& .MuiAvatar-root': {
      position: 'absolute',
      top: 0,
      right: '-20px',
    },
  },
  reset: {
    borderRadius: '20px',
    padding: '0px 4px',
    position: 'absolute',
    top: '-50px',
    right: '5px',
    fontSize: '16px',
    backgroundColor: '#8B2F41',
    border: '1px solid #fff',
    '& .MuiButton-label': {
      textTransform: 'capitalize',
      color: '#fff',
      padding: '4px 12px',
      borderRadius: '20px',
    },
  },
});

export default styles;
