/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-console */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */

/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import fetch from 'fetch';
import axios from 'axios';
import { result } from 'lodash';

const CompounderSlider = () => {
  const [data, setDatas] = useState([]);
  const [timer, setTimer] = useState([]);

  useEffect(() => {
    sliders();
    Timer();
  }, []);

  const Timer = () => {
    axios
      .get('https://d3tqbirbz4ci3z.cloudfront.net/api/get/timer')
      .then(response => {
        // console.log("myresult",response)

        setTimer(response.data.data[0].value);
      })
      .catch(error => {
        console.log(error);
      });
  };

  console.log('timerresult', timer);

  const sliders = () => {
    axios
      .get('https://d3tqbirbz4ci3z.cloudfront.net/api/get/slider/by/62df9a98e5dad2a1b14fbf6e')
      .then(response => {
        setDatas(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  console.log('my data', data);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: timer,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        {data.length > 0 &&
          data.map((v, i) => {
            return (
              <div>
                <div className="hero-slider2">
                  <a href={v.link} target="_blank">
                    <img src={v.slideimg} />
                  </a>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};
export default CompounderSlider;
