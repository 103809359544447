export const bscPools = [
  {
    id: 'knight',
    logo: 'single-assets/KNIGHT.png',
    name: 'Knight',
    token: 'Knight',
    tokenDescription: 'Knightswap',
    tokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    tokenDecimals: 18,
    earnedToken: 'mooKnight',
    earnedTokenAddress: '0x2dDf713b8F154e557C61ED5f7370B20eCf2A0224',
    earnContractAddress: '0x2dDf713b8F154e557C61ED5f7370B20eCf2A0224',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'KNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KnightSwap',
    assets: ['KNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    id: 'knight-knight-busd',
    name: 'KNIGHT-BUSD LP',
    token: 'KNIGHT-BUSD',
    tokenDescription: 'Knightswap',
    tokenAddress: '0xA12e1e2E2dea79694448aB9ef63bd4D82a26d90c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooKnightswapKnight-Busd',
    earnedTokenAddress: '0x4F4e9E30664d138dF7Ba17f4C5F36eD181797F4a',
    earnContractAddress: '0x4F4e9E30664d138dF7Ba17f4C5F36eD181797F4a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-knight-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    stratType: 'StratLP',
    platform: 'KnightSwap',
    withdrawalFee: '0.1%',
    assets: ['KNIGHT', 'BUSD'],
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    knightZapp: true,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    ],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
  },

  {
    id: 'knight-bnb-busd',
    name: 'BNB-BUSD LP',
    token: 'BNB-BUSD',
    tokenDescription: 'Knightswap',
    tokenAddress: '0x28f8ED3Bb8795b11e9be8A6015aDd73ef7Cd3a14',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MooKnightswapBNB-Busd',
    earnedTokenAddress: '0x43c13e76b8e7Da42Bdd9dD6dF6D9E27b799313B4',
    earnContractAddress: '0x43c13e76b8e7Da42Bdd9dD6dF6D9E27b799313B4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-bnb-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    stratType: 'StratLP',
    platform: 'KnightSwap',
    withdrawalFee: '0.1%',
    assets: ['BNB', 'BUSD'],
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/ETH',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    knightZapp: true,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
  },

  {
    id: 'knight-knight-bnb',
    // logo: 'degens/WINGS-BNB.svg',
    name: 'KNIGHT-BNB LP',
    token: 'KNIGHT-BNB LP',
    tokenDescription: 'Knightswap',
    tokenAddress: '0xd71710c21FBa293eB12E0CA079Fe37f50AD17d42',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKnightswapKNIGHT-BNB',
    earnedTokenAddress: '0x691AD7F14CD6D776037129dBad8D1B1485c887C8',
    earnContractAddress: '0x691AD7F14CD6D776037129dBad8D1B1485c887C8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-knight-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KnightSwap',
    assets: ['KNIGHT', 'BNB'],
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/add/ETH/0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    createdAt: 1621578983,
    knightZapp: true,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    ],
  },

  {
    id: 'knight-busd-guard',
    logo: 'degens/Guard-BUSD.svg',
    name: 'BUSD-GUARD LP',
    token: 'BUSD-GUARD LP',
    tokenDescription: 'Knightswap',
    tokenAddress: '0xfC84B031a5221CED00b6470744aF5e5dA5710dDC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKnightswapBUSD-GUARD',
    earnedTokenAddress: '0xa631648D390916aCAAD6BECd60375BdC11B4bdd1',
    earnContractAddress: '0xa631648D390916aCAAD6BECd60375BdC11B4bdd1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-busd-guard',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KnightSwap',
    assets: ['BUSD', 'GUARD'],
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    createdAt: 1621583380,
    knightZapp: true,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    ],
  },

  {
    id: 'knight-usdt-guard',
    logo: 'degens/Guard-BUSD.svg',
    name: 'USDT-GUARD LP',
    token: 'USDT-GUARD LP',
    tokenDescription: 'Knightswap',
    tokenAddress: '0xd55cbda67576e2620Cf4c869C031dC845502067D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooKnightswapUSDT-GUARD',
    earnedTokenAddress: '0x4F7b381CddF8cF17e93e7420ADaFfbB6d8C00858',
    earnContractAddress: '0x4F7b381CddF8cF17e93e7420ADaFfbB6d8C00858',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-usdt-guard',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KnightSwap',
    assets: ['USDT', 'GUARD'],
    addLiquidityUrl:
      'https://app.knightswap.financial/add/0x55d398326f99059fF775485246999027B3197955/0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    createdAt: 1621583380,
    knightZapp: true,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x55d398326f99059fF775485246999027B3197955',
    ],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    ],
  },

  {
    id: 'knight-forge-bnb',
    logo: 'degens/FORGE-BNB.svg',
    name: 'FORGE-BNB LP',
    token: 'FORGE-BNB LP',
    tokenDescription: 'Knightswap',
    tokenAddress: '0xd92A1F9d2EE57C8482629aAe314a0412E778AE54',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Knightswap FORGE-BNB',
    earnedTokenAddress: '0x5Da8D8FaA9e52EBeF64d8C22484Ee789d8D39282',
    earnContractAddress: '0x5Da8D8FaA9e52EBeF64d8C22484Ee789d8D39282',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-forge-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KnightSwap',
    assets: ['FORGE', 'BNB'],
    addLiquidityUrl:
      'https://dex.knightswap.financial/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    createdAt: 1621583380,
    knightZapp: false,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x5138868Ed1814Be113227b8c6025cdc46d9D1D16',
    ],
    token1Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
  },

  {
    id: 'banana-bananav2',
    logo: 'degens/BANANA.svg',
    name: 'BANANA',
    token: 'BANANA',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBanana',
    earnedTokenAddress: '0xea58f8b7Be527179a83f94Ed2932540afA66f0eC',
    earnContractAddress: '0xea58f8b7Be527179a83f94Ed2932540afA66f0eC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BANANA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
    createdAt: 1628853807,
  },

  {
    id: 'banana-busd-bnb',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x51e6D27FA57373d8d4C256231241053a70Cb1d93',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeBUSD-BNB',
    earnedTokenAddress: '0x6dCD49F5ff60E0a24e1aee055a23Dc8E08992070',
    earnContractAddress: '0x6dCD49F5ff60E0a24e1aee055a23Dc8E08992070',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BUSD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    createdAt: 1629037860,
    knightZapp: true,
    routerAddress: '0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7',
    token0Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
  },

  {
    id: 'banana-eth-bnb',
    name: 'ETH-BNB LP',
    token: 'ETH-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xA0C3Ef24414ED9C9B456740128d8E63D016A9e11',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeETH-BNB',
    earnedTokenAddress: '0x763dd97BDc92370219918b3604d997C32AB9d83E',
    earnContractAddress: '0x763dd97BDc92370219918b3604d997C32AB9d83E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-eth-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ETH', 'BNB'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    createdAt: 1629037614,
    knightZapp: true,
    routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    ],
    token1Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
  },

  {
    id: 'banana-link-bnb',
    logo: 'degens/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x092ADA3818DB7FBb8e0A2124Ff218C5125C1CcE6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeLINK-BNB',
    earnedTokenAddress: '0x7F71287cEE06cC189Fe11c0aDAF436899Fa8829a',
    earnContractAddress: '0x7F71287cEE06cC189Fe11c0aDAF436899Fa8829a',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-link-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['LINK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    createdAt: 1621068362,
    knightZapp: true,
    routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
    token0Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    ],
  },

  {
    id: 'banana-ada-bnb',
    logo: 'degens/ADA-BNB.svg',
    name: 'ADA-BNB LP',
    token: 'ADA-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x40d4543887E4170A1A40Cd8dB15A6b297c812Cb1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooApeADA-BNB',
    earnedTokenAddress: '0x5aEd353BbcBD2FFE2f63e7C0FaC425BAdDB1984E',
    earnContractAddress: '0x5aEd353BbcBD2FFE2f63e7C0FaC425BAdDB1984E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ada-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ADA', 'BNB'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    createdAt: 1621017761,
    knightZapp: true,
    routerAddress: '0xC0788A3aD43d79aa53B09c2EaCc313A787d1d607',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    ],
    token1Path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'],
  },

  {
    id: 'cake-cakev2',
    logo: 'single-assets/CAKE.svg',
    name: 'CAKE',
    token: 'CAKE',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    tokenDecimals: 18,
    earnedToken: 'mooCakeV2',
    earnedTokenAddress: '0x768779E43eC267B8C6079fFACb72bD00C93b8dD4',
    earnContractAddress: '0x768779E43eC267B8C6079fFACb72bD00C93b8dD4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Cake',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE'],
    withdrawalFee: '0%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    createdAt: 1623414597,
  },

  // {
  //   id: 'knightbtcb2',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'BTCB',
  //   token: 'BTCB',
  //   tokenDescription: 'Knightswap',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   earnedToken: 'Knight BTCB Vault',
  //   earnedTokenAddress: '0x70fd8e03F9Ac554109Ee5dA2897b66edB120a17C',
  //   earnContractAddress: '0x70fd8e03F9Ac554109Ee5dA2897b66edB120a17C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'KnightSwap',
  //   assets: ['BTCB'],
  //   withdrawalFee: '0%',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   buyTokenUrl:
  //     'https://dex.knightswap.financial/#/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1623414597,
  //   status: 'refund',
  //   status: 'eol',
  // },

  // {
  //   id: 'knightbtcb',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'BTCB',
  //   token: 'BTCB',
  //   tokenDescription: 'Knightswap',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   earnedToken: 'Knight BTCB Vault',
  //   earnedTokenAddress: '0xA7adFd5BA43268f6f8592CBE740d6bD5c9f0d0E2',
  //   earnContractAddress: '0xA7adFd5BA43268f6f8592CBE740d6bD5c9f0d0E2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'KnightSwap',
  //   assets: ['BTCB'],
  //   withdrawalFee: '0%',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   buyTokenUrl:
  //     'https://dex.knightswap.financial/#/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1623414597,
  // },

  {
    id: 'cakev2-usdc-busd',
    logo: 'busd-pairs/USDC-BUSD.svg',
    name: 'USDC-BUSD LP',
    token: 'USDC-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2USDC-BUSD',
    earnedTokenAddress: '0x695Aa6E98B9039Dd234c68e5A2D60AB59faCc0d5',
    earnContractAddress: '0x695Aa6E98B9039Dd234c68e5A2D60AB59faCc0d5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-usdc-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['USDC', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    createdAt: 1619356783,
    knightZapp: true,
    routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    ],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
  },

  {
    id: 'cakev2-ust-busd',
    logo: 'busd-pairs/UST-BUSD.svg',
    name: 'UST-BUSD LP',
    token: 'UST-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x05faf555522Fa3F93959F86B41A3808666093210',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooCakeV2UST-BUSD',
    earnedTokenAddress: '0xd2281D9A49f69D2312f051Ffad08824d2b2158A2',
    earnContractAddress: '0xd2281D9A49f69D2312f051Ffad08824d2b2158A2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ust-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['UST', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x23396cF899Ca06c4472205fC903bDB4de249D6fC&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    createdAt: 1619369585,
    knightZapp: true,
    routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    ],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
  },

  {
    id: 'belt-4belt',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    name: 'BUSD/USDT/USDC/DAI',
    token: '4BELT LP',
    tokenDescription: 'Belt',
    tokenAddress: '0x9cb73F20164e399958261c289Eb5F9846f4D1404',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBelt4Belt',
    earnedTokenAddress: '0x10d2D53775676F394883D5e50dd0cd8056CBFf9C',
    earnContractAddress: '0x10d2D53775676F394883D5e50dd0cd8056CBFf9C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-4belt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['USDT', 'BUSD', 'USDC', 'DAI', '4BELT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://belt.fi/',
    createdAt: 1619502882,
  },

  {
    id: 'belt-beltbnb',
    logo: 'single-assets/BNB.png',
    name: 'beltBNB',
    token: 'beltBNB',
    tokenDescription: 'Belt',
    tokenAddress: '0xa8Bb71facdd46445644C277F9499Dd22f6F0A30C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltBNB',
    earnedTokenAddress: '0x721D74D9078f9BEAbAcE7D211b761ec52209Af87',
    earnContractAddress: '0x721D74D9078f9BEAbAcE7D211b761ec52209Af87',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-beltbnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://belt.fi/',
    createdAt: 1619542263,
  },

  {
    id: 'belt-beltbtc',
    logo: 'single-assets/BTCB.svg',
    name: 'beltBTC',
    token: 'beltBTC',
    tokenDescription: 'Belt',
    tokenAddress: '0x51bd63F240fB13870550423D208452cA87c44444',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltBTC',
    earnedTokenAddress: '0x8990C7c107A5a4f0F9E4d4BC0a0683E9f48EA382',
    earnContractAddress: '0x8990C7c107A5a4f0F9E4d4BC0a0683E9f48EA382',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-beltbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['BTCB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://belt.fi/',
    createdAt: 1619606003,
  },

  {
    id: 'belt-belteth',
    logo: 'single-assets/ETH.svg',
    name: 'beltETH',
    token: 'beltETH',
    tokenDescription: 'Belt',
    tokenAddress: '0xAA20E8Cb61299df2357561C2AC2e1172bC68bc25',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeltETH',
    earnedTokenAddress: '0x9325c432557D069e10455D90aBA622d74AE510d5',
    earnContractAddress: '0x9325c432557D069e10455D90aBA622d74AE510d5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-belteth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://belt.fi/',
    createdAt: 1619606249,
  },

  {
    id: 'jetswap-busd-guard',
    logo: 'degens/Guard-BUSD.svg',
    name: 'BUSD-GUARD LP',
    token: 'BUSD-GUARD JLP',
    tokenDescription: 'JetSwap',
    tokenAddress: '0x94C9cD30499D531CF0b68E1D347625B06C16dF85',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooJetswapBUSD-GUARD',
    earnedTokenAddress: '0x0D2106A1a19c9D8E78fbF4C269B703F3a0882Ed8',
    earnContractAddress: '0x0D2106A1a19c9D8E78fbF4C269B703F3a0882Ed8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'jetswap-busd-guard',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'JetSwap',
    assets: ['BUSD', 'GUARD'],
    addLiquidityUrl:
      'https://exchange.jetswap.finance/#/add/0xF606bd19b1E61574ED625d9ea96C841D4E247A32/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    createdAt: 1621583380,
    knightZapp: true,
    routerAddress: '0x05E61E0cDcD2170a76F9568a110CEe3AFdD6c46f',
    token0Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
      '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
    ],
    token1Path: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    ],
    status: 'refund',
    status: 'eol',
  },

  {
    id: 'knight',
    logo: 'degens/DEP.png',
    name: 'Deap Raid Pool',
    token: 'Knight',
    tokenDescription: 'Raid Pools',
    tokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    tokenDecimals: 18,
    earnedToken: 'KNIGHTDEAPCOINPOOL',
    earnedTokenAddress: '0xBE29e2a5aEB1ed92429dDCc9eD38B1BaAe4D7554',
    earnContractAddress: '0xBE29e2a5aEB1ed92429dDCc9eD38B1BaAe4D7554',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'KNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Raid Pools',
    assets: ['KNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  },

  {
    id: 'knight',
    logo: 'degens/LIBERO.png',
    name: 'Libero Raid Pool',
    token: 'Knight',
    tokenDescription: 'Raid Pools',
    tokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    tokenDecimals: 18,
    earnedToken: 'KNIGHTLIBERORAIDPOOL',
    earnedTokenAddress: '0x1eb519e1F7B6E8a07Ae3F20ca1Dd8D50d2681C36',
    earnContractAddress: '0x1eb519e1F7B6E8a07Ae3F20ca1Dd8D50d2681C36',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'KNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Raid Pools',
    assets: ['KNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    status: 'refund',
    status: 'eol',
  },

  {
    id: 'knight',
    logo: 'degens/FORGE.png',
    name: 'Forge Raid Pool',
    token: 'Knight',
    tokenDescription: 'Raid Pools',
    tokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    tokenDecimals: 18,
    earnedToken: 'KNIGHTForgeRAIDPOOL',
    earnedTokenAddress: '0x1b81F28f008544F6D0F4FEAEB5d210154E3E15c8',
    earnContractAddress: '0x1b81F28f008544F6D0F4FEAEB5d210154E3E15c8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'KNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Raid Pools',
    assets: ['KNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
    status: 'refund',
    status: 'eol',
  },

  {
    id: 'knightbtcb',
    logo: 'single-assets/BTCB.svg',
    name: 'BTCB',
    token: 'BTCB',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    tokenDecimals: 18,
    earnedToken: 'Knight BTCB Vault',
    earnedTokenAddress: '0x07702E46EE0AE0D77c4aF097Fae1797c49Bd43f4',
    earnContractAddress: '0x07702E46EE0AE0D77c4aF097Fae1797c49Bd43f4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BTCB',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    riskLevel: 'Lower',
    assets: ['BTCB'],
    withdrawalFee: '0%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    buyTokenUrl:
      'https://app.knightswap.financial/?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    createdAt: 1623414597,
  },

  {
    id: 'pancake-eth-wbnb',
    logo: 'single-assets/ETH.svg',
    name: 'ETH',
    token: 'ETH',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    tokenDecimals: 18,
    earnedToken: 'Knight ETH Vault',
    earnedTokenAddress: '0xE4BA24454cC249c114B32AEcf76C45ee6BDBc6df',
    earnContractAddress: '0xE4BA24454cC249c114B32AEcf76C45ee6BDBc6df',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'ETH',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    riskLevel: 'Lower',
    assets: ['ETH'],
    withdrawalFee: '0%',
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    buyTokenUrl:
      'https://app.knightswap.financial/?outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    createdAt: 1623414597,
  },

  // {
  //   id: 'knight',
  //   logo: 'degens/JOKE.png',
  //   name: 'Joke Raid Pool',
  //   token: 'Knight',
  //   tokenDescription: 'Raid Pools',
  //   tokenAddress: '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  //   tokenDecimals: 18,
  //   earnedToken: 'KNIGHTJOKERAIDPOOL',
  //   earnedTokenAddress: '0x79C1098f258A1A4C5c2AEc0a270358Ae62bb5096',
  //   earnContractAddress: '0x79C1098f258A1A4C5c2AEc0a270358Ae62bb5096',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'KNIGHT',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Raid Pools',
  //   assets: ['KNIGHT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://dex.knightswap.financial/#/swap?outputCurrency=0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
  // },
];

// BSC

// Belt:
// busd/usdt/esdc/dai    1
// beltbnb               1
// beltbtc               1
// belthEth              1

// Apeswap (gross)
// Banana    1
// busd-bnb  1
// eth-bnb   1
// Link-bnb   1
// ada-eth or ada-bnb (wolf den likes ada)      1

// Pancake:
// Cake           1
// usdc-busd      1
// UST-busd       1

// Jetswap:
// Guard/BUSD
