const styles = theme => ({
  notice: {
    padding: 25,
    marginBottom: 25,
    textAlign: 'center',
    color: theme.palette.primary.main,
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  message: {
    marginBottom: 15,
    fontSize: '20px',
  },
  actions: {
    margin: '-10px -10px 15px 0',
  },
  button: {
    border: '1px solid #fff',
    padding: '8px 20px',
    backgroundColor: '#ffffff47',
    textTransform: 'none',
    margin: '10px 10px 0 0',
    borderRadius: '30px',
    fontSize: '14px',
  },
  note: {
    marginBottom: 15,
    fontStyle: 'italic',
  },
  error: {
    color: 'red',
  },
});

export default styles;
