import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        {/* <div className={classes.title}>Dark.knightswap.financial </div>
        <a
          href="https://knights.gitbook.io/darkknight/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-book ${classes.linkIcon}`} />
          <span>{t('docs')}</span>
        </a> */}

        {/* <a
          href="https://medium.com/knight-bsc-dark-knight-ftm"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-file-alt ${classes.linkIcon}`} />
          <span>{t('blog')}</span>
        </a>

        <a
          href="https://discord.gg/5ZUBNdcsAu"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-comments ${classes.linkIcon}`} />
          <span>{t('forum')}</span>
        </a> */}

        {/* <a
          href="https://github.com/beefyfinance"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-github ${classes.linkIcon}`} />
          <span>{t('source')}</span>
        </a> */}
      </div>

      <div className={classes.column}>
        {/* <div className={classes.title}>{t('products')}</div>
        <a
          href="https://darkdex.knightswap.financial/#/swap"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-landmark ${classes.linkIcon}`} />
          <span>Dex</span>
        </a>

        <a
          href="https://dark.knightswap.financial/raid"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-vote-yea ${classes.linkIcon}`} />
          <span>Raid</span>
        </a>

        <a
          href="https://undead.to/marketplace"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-hand-holding-usd ${classes.linkIcon}`} />
          <span>NFT Marketplace</span>
        </a> */}
      </div>

      <div className={classes.column}>
        {/* <div className={classes.title}>{t('socials')}</div>
        <a
          href="https://twitter.com/WIZARD_BSC"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-twitter ${classes.linkIcon}`} />

          <span>twitter</span>
        </a> */}
        {/* <a
          href="https://t.me/knightswap"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-telegram ${classes.linkIcon}`} />

          <span>telegram</span>
        </a> */}
        {/* <a
          href="https://discord.gg/5ZUBNdcsAu"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-discord ${classes.linkIcon}`} />
          <span>discord</span>
        </a> */}
      </div>
    </div>
  );
};

export default memo(Footer);
