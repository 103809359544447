export const bsctPools = [
  {
    id: 'knight',
    logo: 'single-assets/BIFI.png',
    name: 'Knight',
    token: 'Knight',
    tokenDescription: 'Knightswap',
    tokenAddress: '0x4E8733621cC3Ee2Da614485381C3b4A374921395',
    tokenDecimals: 18,
    earnedToken: 'mooBIFI',
    earnedTokenAddress: '0xAbD044b3d40C5B31aeb0Eaf407B63e58C9653520',
    earnContractAddress: '0xAbD044b3d40C5B31aeb0Eaf407B63e58C9653520',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'Knight',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'KnightSwap',
    assets: ['Knight'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BIFI',
  },
  {
    id: 'knight-bnb-busd',
    name: 'BNB-BUSD LP',
    token: 'BNB-BUSD',
    tokenDescription: 'Knightswap',
    tokenAddress: '0x46F2cC2731f80Aa658B4408F9B533a0d19e9516E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moov2BNB-BUSD',
    earnedTokenAddress: '0xd862F669700d0703Ac0805069d2f2AF4B853E518',
    earnContractAddress: '0xd862F669700d0703Ac0805069d2f2AF4B853E518',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'knight-bnb-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    stratType: 'StratLP',
    platform: 'KnightSwap',
    withdrawalFee: '0.2%',
    assets: ['BNB', 'BUSD'],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x55d398326f99059fF775485246999027B3197955/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  },
];
