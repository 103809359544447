import { knightZapp } from '../configure';
import { enqueueSnackbar } from '../common/redux/actions';

export const depositZapp = async ({ web3, address, amount, contractAddress, dispatch, pool }) => {
  const contract = new web3.eth.Contract(knightZapp, contractAddress);
  const data = await _deposit({ web3, contract, amount, address, dispatch, pool });
  return data;
};

const _deposit = ({ web3, contract, amount, address, dispatch, pool }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .zapIn(
        '0x0000000000000000000000000000000000000000',
        0,
        pool.tokenAddress,
        pool.token0Path,
        pool.token1Path,
        pool.routerAddress,
        pool.routerAddress,
        pool.routerAddress,
        pool.earnedTokenAddress
      )
      .send({ from: address, value: amount })
      .on('transactionHash', function (hash) {
        console.log(hash);
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
            },
            hash,
          })
        );
      })
      .on('receipt', function (receipt) {
        console.log(receipt);
        resolve();
      })
      .on('error', function (error) {
        console.log(error);
        reject(error);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

// 0xe69b1167000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000
// 00000000000000000000000000000000000fc84b031a5221ced00b6470744af5e5da5710ddc000000000000000000000000000000000000000000000000000
// 0000000000120000000000000000000000000000000000000000000000000000000000000018000000000000000000000000005e61e0cdcd2170a76f9568a1
// 10cee3afdd6c46f00000000000000000000000005e61e0cdcd2170a76f9568a110cee3afdd6c46f00000000000000000000000005e61e0cdcd2170a76f956
// 8a110cee3afdd6c46f000000000000000000000000a631648d390916acaad6becd60375bdc11b4bdd1000000000000000000000000000000000000000000
// 0000000000000000000002000000000000000000000000bb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c000000000000000000000000e9e7cea3dedca5
// 984780bafc599bd69add087d560000000000000000000000000000000000000000000000000000000000000002000000000000000000000000bb4cdb9cbd36b
// 01bd1cbaebf2de08d9173bc095c000000000000000000000000f606bd19b1e61574ed625d9ea96c841d4e247a32
