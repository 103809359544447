export const fantomPools = [
  {
    id: 'dKNIGHT',
    logo: 'single-assets/dKNIGHT.svg',
    name: 'dKNIGHT',
    token: 'dKNIGHT',
    tokenDescription: 'dKnightSwap',
    tokenAddress: '0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
    tokenDecimals: 18,
    earnedToken: 'mooKnight',
    earnedTokenAddress: '0x94d64470B504847e0EcA3A18D921433deD40A206',
    earnContractAddress: '0x94d64470B504847e0EcA3A18D921433deD40A206',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'dKNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'dKnightSwap',
    assets: ['dKNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://darkdex.knightswap.financial/#/swap?outputCurrency=0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
  },

  {
    id: 'dknight-dknight-usdc',
    name: 'dKNIGHT-USDC LP',
    token: 'dKNIGHT-USDC',
    tokenDescription: 'dKnightswap',
    tokenAddress: '0x68D47D67b893c44A72BCAC39b1b658D4Cbdf87CA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'MoodarkKnightSwapdKNIGHT-USDC',
    earnedTokenAddress: '0xdBA79696FD4d2B44487f556e171CA99D4Da743c6',
    earnContractAddress: '0xdBA79696FD4d2B44487f556e171CA99D4Da743c6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dknight-dknight-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    stratType: 'StratLP',
    platform: 'dKnightSwap',
    withdrawalFee: '0.1%',
    assets: ['dKNIGHT', 'USDC'],
    addLiquidityUrl:
      'https://darkdex.knightswap.financial/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
    buyTokenUrl:
      'https://darkdex.knightswap.financial/#/swap?outputCurrency=0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
  },

  {
    id: 'dknight-dknight-ftm',
    // logo: 'degens/WINGS-BNB.svg',
    name: 'dKNIGHT-FTM LP',
    token: 'dKNIGHT-FTM LP',
    tokenDescription: 'dKnightswap',
    tokenAddress: '0xD519AE779eb7987cdddA63be2CEffE0C35759E04',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'moodKnightdKNIGHT-FTM',
    earnedTokenAddress: '0x631e253c932016EEc6F90bdD81C31818903659f2',
    earnContractAddress: '0x631e253c932016EEc6F90bdD81C31818903659f2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'dknight-dknight-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'dKnightSwap',
    assets: ['dKNIGHT', 'FTM'],
    addLiquidityUrl:
      'https://darkdex.knightswap.financial/#/add/ETH/0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
    buyTokenUrl:
      'https://darkdex.knightswap.financial/#/swap?outputCurrency=0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
    createdAt: 1621578983,
  },

  {
    id: 'boo-btc-eth',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xEc454EdA10accdD66209C57aF8C12924556F3aBD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBTC-ETH',
    earnedTokenAddress: '0x721D74D9078f9BEAbAcE7D211b761ec52209Af87',
    earnContractAddress: '0x721D74D9078f9BEAbAcE7D211b761ec52209Af87',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    buyTokenUrl:
      'https://spookyswap.finance/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1642672280,
  },

  {
    id: 'boo-ftm-usdc',
    name: 'USDC-FTM LP',
    token: 'USDC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooFTM-USDC',
    earnedTokenAddress: '0x8990C7c107A5a4f0F9E4d4BC0a0683E9f48EA382',
    earnContractAddress: '0x8990C7c107A5a4f0F9E4d4BC0a0683E9f48EA382',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-ftm-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['USDC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    createdAt: 1620386549,
  },

  {
    id: 'boo-btc-ftm',
    name: 'WBTC-FTM LP',
    token: 'WBTC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xFdb9Ab8B9513Ad9E419Cf19530feE49d412C3Ee3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooBTC-FTM',
    earnedTokenAddress: '0x9325c432557D069e10455D90aBA622d74AE510d5',
    earnContractAddress: '0x9325c432557D069e10455D90aBA622d74AE510d5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-btc-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WBTC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
    createdAt: 1620500455,
  },

  {
    id: 'boo-eth-ftm',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xf0702249F4D3A25cD3DED7859a165693685Ab577',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBooETH-FTM',
    earnedTokenAddress: '0x0D2106A1a19c9D8E78fbF4C269B703F3a0882Ed8',
    earnContractAddress: '0x0D2106A1a19c9D8E78fbF4C269B703F3a0882Ed8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-eth-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['ETH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1620386666,
  },

  {
    id: 'spirit-ftm-eth',
    name: 'ETH-FTM LP',
    token: 'ETH-FTM SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x613BF4E46b4817015c01c6Bb31C7ae9edAadc26e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritFTM-ETH',
    earnedTokenAddress: '0x691AD7F14CD6D776037129dBad8D1B1485c887C8',
    earnContractAddress: '0x691AD7F14CD6D776037129dBad8D1B1485c887C8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-ftm-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['ETH', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/FTM/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    createdAt: 1644248760,
  },

  {
    id: 'spirit-dai-usdc',
    name: 'DAI-USDC LP',
    token: 'DAI-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x9606D683d03f012DDa296eF0ae9261207C4A5847',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSpiritUSDC-DAI',
    earnedTokenAddress: '0xA8950Aca5456B6560092196df64B88A6B86bcF96',
    earnContractAddress: '0xA8950Aca5456B6560092196df64B88A6B86bcF96',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    createdAt: 1640070468,
  },

  {
    id: 'dKNIGHT',
    logo: 'degens/ASNT.png',
    name: 'Assent Raid Pool',
    token: 'dKNIGHT',
    tokenDescription: 'Raid Pools',
    tokenAddress: '0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
    tokenDecimals: 18,
    earnedToken: 'dKNIGHTASSENTRAID',
    earnedTokenAddress: '0x79C1098f258A1A4C5c2AEc0a270358Ae62bb5096',
    earnContractAddress: '0x79C1098f258A1A4C5c2AEc0a270358Ae62bb5096',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'dKNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Raid Pools',
    assets: ['dKNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://darkdex.knightswap.financial/#/swap?outputCurrency=0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
  },

  {
    id: 'dKNIGHT',
    logo: 'degens/FLIBERO.png',
    name: 'FLibero Raid Pool',
    token: 'dKNIGHT',
    tokenDescription: 'Raid Pools',
    tokenAddress: '0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
    tokenDecimals: 18,
    earnedToken: 'dKnightfLiberoRaid',
    earnedTokenAddress: '0xA2a4cAC44f99810574f58095B700B8DF5FD84fd6',
    earnContractAddress: '0xA2a4cAC44f99810574f58095B700B8DF5FD84fd6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'dKNIGHT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Raid Pools',
    assets: ['dKNIGHT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://darkdex.knightswap.financial/#/swap?outputCurrency=0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
  },
];

// FTM

// btc/eth - Spookyswap            1
// usdc/ft - spookyswap            1
// wbtc/ftm - spookyswap           1
// eth/ftm - spookyswap            1

// optional:
// tomb/ftm - spookyswap (high tvl and hight apy)
// tshare/ftm - spookyswap

// eth/ftm - spiritswap            1
// dai/usdc - spiritswap           1

// Undead/ftm - Jetswap
